<ng-container *ngIf="articles$ | async as articles; else formTemplate">
  <ng-container *ngIf="articleCount > 0; else formTemplate">
    <div class="resolver_sidebar-scrollbar">
      <h5 class="text--lg">Possible matches...</h5>
      <h6>Please pick one of the results from below that matches your reference!</h6>

      <div class="list-item" *ngFor="let article of articles" (click)="confirmMatch(article)">
        <div *ngIf="article?.title" class="list-item_title">{{article.title}}</div>
        <div *ngIf="article?.patent_id" class="list-item_subtitle--grey">
          <span class="sub-title">Patent ID: {{article.patent_id}}</span>
        </div>
        <div *ngIf="article?.authors" class="list-item_subtitle--blue">{{getAuthorNames(article.authors)}}</div>
        <div *ngIf="article?.abstract" class="list-item_subtitle--grey">{{article.abstract | slice:0:500}}{{article.abstract.length > 500 ? '...' : ''}}</div>
        <div *ngIf="article?.journal || article?.year">{{article.journal}} {{article.year}}</div>
      </div>
    </div>

    <div class="resolver_sidebar-footer">
      <button type="button" (click)="clearResults()" class="button button--sm">
        Review metadata
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-template #formTemplate>

  <ng-container *ngIf="!(itemLoading$ | async) else itemLoadingTemplate">

    <div class="resolver_sidebar-header" *ngIf="item">
      <span class="text--lg">Enter metadata...</span>
      <div class="resolver_sidebar-pagination">
        <span *ngIf="collectionName" class="badge">Library: {{ collectionName }}</span>
        <span class="badge">Resolved {{ resolvedItemCount }} of {{ totalItemCount }} items</span>
        <button 
          class="badge badge--link"  
          (click)="onPrevClick()">Previous
        </button>
        <button 
          class="badge badge--link"  
          (click)="onNextClick()">Next
        </button>
      </div>
    </div>
  
    <div class="resolver_sidebar-scrollbar" *ngIf="form">

      <div class="form-group" *ngIf="formTypes.length > 1">
        <label for="title_input">Type:</label>
        <select name="type-select" class="form-control" [(ngModel)]="formType" (ngModelChange)="onFormTypeChange($event)">
          <option [value]="f.key" *ngFor="let f of formTypes">{{f.value}}</option>
        </select>
      </div>

      <ng-container [ngSwitch]="formType">
        <form [formGroup]="form" *ngSwitchCase="'journal_article'">
  
          <div class="form-group">
            <label for="title_input">Title:</label>
            <input type="text"
              class="form-control"
              id="title_input"
              placeholder="Enter article title..."
              formControlName="title">
          </div>
    
          <div class="form-group">
            <label for="authors_input">Authors:</label>
            <rcp-token-field
              class="form-control token_container"
              formControlName="authors"
              placeholder="Enter author here...">
              <ng-template #tokenTemplate let-item="item">
                <span>{{item}}</span>
              </ng-template>
            </rcp-token-field>
          </div>
    
          <div class="form-group">
            <label for="abstract_input">Abstract:</label>
            <textarea type="text"
              rows="4"
              class="form-control"
              id="abstract_input"
              placeholder="Enter article abstract..."
              formControlName="abstract">
            </textarea>
          </div>
    
          <div class="row">
            <div class="form-group col md-8">
              <label for="journal_input">Journal:</label>
              <input type="text"
                class="form-control"
                id="journal_input"
                placeholder="Enter journal..."
                formControlName="journal">
            </div>
    
            <div class="form-group col md-4">
              <label for="year_input">Year:</label>
              <input type="text"
                class="form-control"
                id="year_input"
                placeholder="Enter year..."
                formControlName="year">
              <small class="field-error" *ngIf="!form.controls['year'].valid">Invalid Year format.</small>
            </div>
          </div>
    
          <div class="row">
            <div class="form-group col md-8">
              <label for="doi_input">Digital Object Identifier:</label>
              <input type="text"
                class="form-control"
                id="doi_input"
                placeholder="Enter DOI..."
                formControlName="doi"
                [ngClass]="{ 'form-control--error': !form.controls['doi'].valid }">
              <small class="field-error" *ngIf="!form.controls['doi'].valid">Invalid DOI format.</small>
            </div>
          </div>
    
          <div class="row">
            <div class="form-group col md-8">
              <label for="isbn_input">International Standard Book Number:</label>
              <input type="text"
                class="form-control"
                id="isbn_input"
                placeholder="Enter ISBN..."
                formControlName="isbn"
                [ngClass]="{ 'form-control--error': !form.controls['isbn'].valid }">
              <small class="field-error" *ngIf="!form.controls['isbn'].valid">Invalid ISBN format.</small>
            </div>
          </div>
    
          <div class="row">
            <div class="form-group col md-8">
              <label for="pmid_input">PubMed:</label>
              <input type="text"
                class="form-control"
                id="pmid_input"
                placeholder="Enter PMID..."
                formControlName="pmid"
                [ngClass]="{ 'form-control--error': !form.controls['pmid'].valid }">
              <small class="field-error" *ngIf="!form.controls['pmid'].valid">Invalid PMID format.</small>
            </div>
          </div>
  
          <div class="row">
            <div class="form-group col sm-12">
              <button type="button" (click)="resetForm()" class="button button--sm">Reset Form</button>
            </div>
          </div>
        </form>
  
        <form [formGroup]="form" *ngSwitchCase="'patent'">
          <div class="form-group">
            <label for="patent_id_input">Patent ID:</label>
            <input type="text"
              class="form-control"
              id="patent_id_input"
              placeholder="Enter patent id..."
              formControlName="patent_id">
          </div>
        </form>

      </ng-container>

    </div>
  
    <div class="resolver_sidebar-footer" *ngIf="form">
      <ng-container *ngIf="!(searchLoading$ | async); else searchLoadingTemplate">
        <div class="alert" role="alert" *ngIf="form.invalid">
          Please update the invalid fields.
        </div>
  
        <div class="alert" role="alert" *ngIf="articleCount === 0">
          <strong>No matches were found.</strong><br>Please edit the metadata and try again.
        </div>
  
        <div class="resolver_sidebar-buttons">
          <button 
            type="button" 
            (click)="searchArticles()" 
            class="button button--sm">
            {{ formType === 'journal_article' ? 'Find article' : 'Find match' }}
          </button>
          <button 
            *ngIf="formType === 'journal_article'"
            type="button" 
            (click)="searchBooks()" 
            class="button button--sm">
            Find book
          </button>
          <button 
            *ngIf="(userService.user$ | async)?.role === 'developer' || (userService.user$ | async)?.role === 'administrator'" 
            type="button" 
            (click)="save()" 
            class="button button--sm">
            Save
          </button>
          <button 
            type="button" 
            (click)="markUnresolvable()" 
            class="button button--sm">
            Mark unresolvable
          </button>
        </div>
      </ng-container>
    </div>

  </ng-container>

</ng-template>

<ng-template #searchLoadingTemplate>
  <button type="button" class="button" disabled>Searching...</button>
</ng-template>

<ng-template #itemLoadingTemplate>
  <div class="loader_overlay">
    <rcm-loader class="center-horizontal-vertical"></rcm-loader>
  </div>
</ng-template>

