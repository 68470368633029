// native
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

// service
import { AuthService } from './core/services/auth.service';

// environment
import { environment } from '../environments/environment';

// meta
import { BUILD_VERSION } from 'build-metadata/build';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'Accept': 'application/json',
      },
      setParams: {
        'client': environment.clientName,
        'client_version': BUILD_VERSION
      },
      withCredentials: true
    });

    return next.handle(request).pipe(catchError((response: HttpErrorResponse) => {
      switch (response.status) {
        case 401:
          window.location.href = `${environment.baseUrls.readcube}/authentication?` +
            `client=${environment.clientName}&redirect=${encodeURIComponent(window.location.href)}`;
          return throwError(response);

        case 403:
          this.authService.isAppForbbidenForUser$.next(true);
          this.router.navigateByUrl('');
          return throwError(response);

        default:
          return throwError(response);
      }
    }));
  }
}
