// native
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

// component
import { AppComponent } from './app.component';

// module
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { ResolverModule } from './resolver/resolver.module';
import { HomeModule } from './home/home.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule.forRoot(),
    HomeModule,
    ResolverModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
