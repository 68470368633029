// native
import { Component, OnInit, HostListener } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

// model
import { IItemDataResult, IItem } from 'src/app/models/item.model';

// service
import { ResolverService } from 'src/app/core/services/resolver.service';

// environment
import { environment } from '../../../../environments/environment';

// constants
import {
  JOURNAL_READER_TYPES,
  TITLE_FOUND_EVENT,
  MARKED_EVENT,
  INITIALIZED_EVENT,
  PDF_FILE_TYPE,
  JOURNAL_ARTICLE_TYPE,
  PATENT_READER_TYPES
} from '../../../constants/constants';

@Component({
  selector: 'rcm-resolver',
  templateUrl: './resolver.component.html',
  host: {
    style: 'width: 100%;'
  }
})
export class ResolverComponent implements OnInit {

  item$: Observable<IItemDataResult>;
  readerURL: SafeResourceUrl;
  url$: Observable<IItemDataResult>;
  loadingReaderDocument$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isNonPdfFileTypeNotificationVisible = false;

  isFrameHidden: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    public resolverService: ResolverService
  ) { }

  ngOnInit(): void {
    this.resolverService.item$
      .pipe(
        tap((response: IItemDataResult) => {
          this.isNonPdfFileTypeNotificationVisible = (response.item?.primary_file_type !== PDF_FILE_TYPE);
        }),
        map((response: IItemDataResult) => this.getReaderURL(response.item)),
        map(url => this.sanitizer.bypassSecurityTrustResourceUrl(url))
      )
      .subscribe(url => {
        this.readerURL = url;
        this.loadingReaderDocument$.next(true);

        this.rerenderFrame();
      });
  }

  private getReaderURL(item: IItem): string {
    return `${environment.baseUrls.readcube}/resolve/${item.collection_id}:${item.id}?types=${JOURNAL_READER_TYPES}&matcher=1`;
  }

  private rerenderFrame() {
    // flag to re-render iframe element on each item change
    // due to iframe src being stacked on window history (browser back leads to iframe back bugfix)
    this.isFrameHidden = true;
    setTimeout(() => {
      this.isFrameHidden = false;
    });
  }

  @HostListener('window:message', ['$event'])
  handleReaderMessage(event: MessageEvent) {
    const eventType = event?.data?.type;

    if ([MARKED_EVENT, TITLE_FOUND_EVENT].includes(eventType)) {
      this.resolverService.setSelection(event.data);
      return;
    }

    if (INITIALIZED_EVENT === eventType) {
      this.loadingReaderDocument$.next(false);
    }
  }

  onFormTypeChanged(event) {
    if (!event.formType || !event.item)
      return;
    const types = event.formType === JOURNAL_ARTICLE_TYPE ? JOURNAL_READER_TYPES : PATENT_READER_TYPES;
    this.readerURL = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.baseUrls.readcube}/resolve/${event.item.collection_id}:${event.item.id}?types=${types}&matcher=1`);
  }
}
