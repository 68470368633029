// native
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

// service
import { ApiService } from './api.service';

// model
import { IArticleDataResult, IBookDataResult } from 'src/app/models/article.model';
import { IBooksQueryParams } from 'src/app/models/query/books-query-params.model';

// environment
import { environment } from '../../../environments/environment';

interface IDataSearchQueryParams {
  query?: string;
  sort_by?: string;
  order?: 'asc' | 'desc';
  skip?: number;
  size?: number;
}

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private apiService: ApiService
  ) { }

  searchArticles(params: IDataSearchQueryParams): Observable<IArticleDataResult> {
    const endpointUrl = `${environment.baseUrls.services}/articles/search`;
    const httpParams = this.getHttpParams(params);

    return this.apiService.get(endpointUrl, httpParams).pipe(
      map((response: IArticleDataResult) => {
        response.results
          .forEach(result => {
            result.ext_ids = {
              doi: result['doi'],
              pmid: result['pmid'],
              pmcid: result['pmcid'],
              arxiv: result['arxiv'],
              patent_id: result['patent_id']
            };
          });

        return response;
      })
    );
  }

  createArticlesQuery(params: any): string {
    const query = [];
    if (params.doi || params.pmid || params.isbn || params.patent_id) {
      if (params.doi) {
        query.push(`doi:"${params.doi}"`);
      }
      if (params.isbn) {
        query.push(`isbn:${params.isbn}`);
      }
      if (params.pmid) {
        query.push(`pmid:${params.pmid}`);
      }
      if (params.patent_id) {
        query.push(`patent_id:${params.patent_id}`);
      }
      return query.join(' OR ');
    }
    const r = /[^\w\s]/gi;
    let tmp = '';
    if (params.title) {
      tmp += `${params.title.replace(r, ' ').trim()}`;
    }
    if (params.authors && params.authors.length) {
      tmp += ` ${params.authors.join(' ').replace(r, ' ').trim()}`;
    }
    if (params.journal) {
      tmp += ` ${params.journal.replace(r, ' ').trim()}`;
    }
    if (params.abstract) {
      tmp += ` ${params.abstract.replace(r, ' ').trim()}`;
    }
    if (tmp) {
      query.push(tmp);
    }
    if (params.year) {
      query.push(`year:${parseInt(params.year, 10)}`);
    }
    return query.join(' AND ');
  }

  private getHttpParams(params: IDataSearchQueryParams): HttpParams {
    let httpParams = new HttpParams();
    if (params.query) {
      httpParams = httpParams.append('query', params.query);
    }
    if (params.sort_by) {
      httpParams = httpParams.append('sort_by', params.sort_by);
    }
    if (params.order) {
      httpParams = httpParams.append('order', params.order);
    }
    if (params.size) {
      httpParams = httpParams.append('size', params.size.toString());
    }

    httpParams = httpParams.delete('skip');
    return httpParams;
  }

  createBooksQuery(params: IBooksQueryParams): string {
    const nonCharRegex = /[^\w\s]/gi;
    const query = [];

    if (params.title) {
      query.push(`title=${params.title.replace(nonCharRegex, ' ').trim()}`);
    }

    if (params.authors && params.authors.length) {
      query.push(`author=${params.authors.join(' ').replace(nonCharRegex, ' ').trim()}`);
    }

    if (params.isbn) {
      query.push(`isbn=${params.isbn.trim()}`);
    }

    return query.join('&');
  }

  searchBooks(params: string): Observable<IBookDataResult> {
    const endpointUrl = `${environment.baseUrls.playground}/books?${params}`;

    return this.apiService.get(endpointUrl);
  }
}
