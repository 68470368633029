import { NgModule, ModuleWithProviders } from "@angular/core";
// native
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppHttpInterceptor } from '../app-http-interceptor.service';

// service
import { ApiService } from './services/api.service';
import { UserService } from './services/user.service';
import { SearchService } from './services/search.service';
import { ResolverService } from './services/resolver.service';
import { CollectionService } from './services/collection.service';
import { LocalStorageService } from './services/local-storage.service';

// component
import { HeaderComponent } from './components/header/header.component';

// module
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    HeaderComponent
  ],
  exports: [
    HeaderComponent
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ApiService,
        UserService,
        SearchService,
        ResolverService,
        CollectionService,
        LocalStorageService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AppHttpInterceptor,
          multi: true
        },
      ]
    };
  }
}