// native
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription, Observable, ReplaySubject, throwError } from 'rxjs';
import { tap, map, publishReplay, refCount, catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

// service
import { ResolverService } from 'src/app/core/services/resolver.service';
import { SearchService } from 'src/app/core/services/search.service';
import { CollectionService } from 'src/app/core/services/collection.service';
import { UserService } from 'src/app/core/services/user.service';

// model
import { IItem, IItemResolveResponse } from 'src/app/models/item.model';
import { IReaderMessage } from 'src/app/models/selection.model';
import { IArticle, IArticleAuthor, IArticleDataResult, IBookDataResult } from 'src/app/models/article.model';

// constant
import { JOURNAL_ARTICLE_TYPE, MESSAGE_TYPE_TO_FIELD_MAP, PATENT_TYPE } from 'src/app/constants/constants';

interface ResolverRouteParams {
  collectionId: string;
}

@Component({
  selector: 'rcm-resolver-sidebar',
  templateUrl: './resolver-sidebar.component.html'
})
export class ResolverSidebarComponent implements OnInit {

  @Output() typeChange: EventEmitter<any> = new EventEmitter();

  item: IItem;
  collectionId: string;
  collectionName: string;
  form: UntypedFormGroup;
  itemSubscription: Subscription;
  selectionSubscription: Subscription;
  valueChangeSubscription: Subscription;
  error: string;

  articles$: Observable<IArticle[]>;
  books$: Observable<IArticle[]>;

  articleCount: number;
  totalItemCount: number;
  resolvedItemCount: number = 0;

  searchLoading$ = new ReplaySubject<boolean>(1);
  itemLoading$ = new ReplaySubject<boolean>(1);

  formType = '';
  formTypes = [];

  constructor(
    private resolverService: ResolverService,
    private searchService: SearchService,
    private router: Router,
    private route: ActivatedRoute,
    private collectionService: CollectionService,

    public userService: UserService
  ) { }

  ngOnInit() {
    this.formTypes.push(
      {
        key: JOURNAL_ARTICLE_TYPE,
        value: 'Journal Article'
      },
      {
        key: PATENT_TYPE,
        value: 'Patent'
      });

    this.collectionName = this.collectionService.currentCollection?.name;

    this.route.params.subscribe((params: ResolverRouteParams) => {
      this.collectionId = params.collectionId;
      this.resolverService.getInitialItem(this.collectionId);
    });

    this.selectionSubscription = this.resolverService.selection$.subscribe((message: IReaderMessage) => {
      if (message.type === 'marked' && this.form) {
        this.onTextSelected(message);
      } else if (message.type === 'title-found' && this.form) {
        this.onAutomaticTitleFound(message);
      }
    });

    this.itemSubscription = this.resolverService.item$.subscribe(response => {
      this.itemLoading$.next(false);

      this.item = response.item;

      if (response.total)
        this.totalItemCount = response.total;

      this.formType = JOURNAL_ARTICLE_TYPE;

      this.initForm(this.item, this.formType);
    });
  }

  ngOnDestroy() {
    this.itemSubscription?.unsubscribe();
    this.selectionSubscription?.unsubscribe();
    this.valueChangeSubscription?.unsubscribe();
  }

  initForm(item: IItem, formType: string) {
    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
    }

    this.form = this.resolverService.initForm(item, formType);

    this.valueChangeSubscription = this.form.valueChanges.subscribe(() => {
      this.articleCount = null;
    });
  }

  onFormTypeChange(formType: string) {
    this.formType = formType;
    this.initForm(this.item, formType);
    this.typeChange.emit({formType, item: this.item});
  }

  searchArticles() {
    let query;

    if (this.formType === PATENT_TYPE) {
      let patentId = this.form.controls['patent_id'].value;

      // escape commas and new lines
      if (patentId && patentId.replace) {
        patentId = patentId.replace(/,/g, '');
        patentId = patentId.replace(/\n/g, '');
      }

      query = this.searchService.createArticlesQuery({
        patent_id: patentId
      });
    } else {
      query = this.searchService.createArticlesQuery({
        title: this.form.controls['title'].value,
        authors: this.form.controls['authors'].value,
        journal: this.form.controls['journal'].value,
        year: this.form.controls['year'].value,
        doi: this.form.controls['doi'].value,
        pmid: this.form.controls['pmid'].value,
        isbn: this.form.controls['isbn'].value,
        abstract: this.form.controls['abstract'].value,
      });
    }

    this.searchLoading$.next(true);

    this.articles$ = this.searchService.searchArticles({ query: query, size: 5 })
      .pipe(
        tap(() => this.searchLoading$.next(false)),
        catchError(this.handleError),
        tap((response: IArticleDataResult) => {
          this.articleCount = (response.results && response.results.length);
        }),
        map(response => response.results),
        publishReplay(1),
        refCount()
      );
  }

  searchBooks() {
    const query = this.searchService.createBooksQuery({
      title: this.form.controls['title'].value,
      authors: this.form.controls['authors'].value,
      isbn: this.form.controls['isbn'].value
    });

    this.searchLoading$.next(true);

    this.articles$ = this.searchService.searchBooks(query)
      .pipe(
        tap(() => this.searchLoading$.next(false)),
        catchError(this.handleError),
        tap((response: IBookDataResult) => {
          this.articleCount = (response.results && response.results.length);
        }),
        map(response => response.results),
        publishReplay(1),
        refCount()
      );
  }

  confirmMatch(article: IArticle) {
    this.clearResults();
    this.itemLoading$.next(true);

    // item can be resolved only if article has ext_ids, 
    // otherwise article is a book and can only be used to update item
    if (article.ext_ids) {
      this.confirmArticleMatch(article);
      return;
    }

    this.confirmBookMatch(article);
  }

  private confirmArticleMatch(article: IArticle) {
    this.resolverService.resolveItem(this.collectionId, this.item.id, article.ext_ids).subscribe((response: IItemResolveResponse) => {
      this.resolvedItemCount++;
      this.navigateToNextItem(response.collection_resolved);
    }, error => {
      this.navigateToNextItem();
    });
  }

  private confirmBookMatch(book: IArticle) {
    const itemPatch: Partial<IItem> = {
      article: {}
    };

    for (let key in book) {
      itemPatch.article[key] = book[key];
    }

    this.resolverService.updateItem(this.collectionId, this.item.id, itemPatch).subscribe((response: IItemResolveResponse) => {
      this.resolvedItemCount++;
      this.navigateToNextItem(response.collection_resolved);
    }, error => {
      this.navigateToNextItem();
    });
  }

  clearResults() {
    this.articleCount = null;
    this.articles$ = null;
  }

  getAuthorNames(authors: IArticleAuthor[]): string {
    return authors.length && authors.map(a => a.name).join(', ');
  }

  markUnresolvable() {
    this.itemLoading$.next(true);

    this.resolverService.markUnresolvable(this.collectionId, this.item.id).subscribe((response: IItemResolveResponse) => {
      this.resolvedItemCount++;
      this.navigateToNextItem(response.collection_resolved);
    }, error => {
      this.navigateToNextItem();
    });
  }

  save() {
    this.itemLoading$.next(true);

    const itemPatch: Partial<IItem> = this.resolverService.getItemPatch(this.form, this.formType);

    this.resolverService.updateItem(this.collectionId, this.item.id, itemPatch).subscribe((response: IItemResolveResponse) => {
      this.form.markAsPristine();
      this.resolvedItemCount++;
      this.navigateToNextItem(response.collection_resolved);
    }, error => {
      this.navigateToNextItem();
    });
  }

  navigateToNextItem(isCollectionResolved = false) {
    if (isCollectionResolved) {
      this.itemLoading$.next(false);
      this.router.navigateByUrl('success');
      return;
    }

    this.resolverService.getNextItem(this.collectionId, this.item.id);
  }

  navigateToPreviousItem() {
    this.resolverService.getPreviousItem(this.collectionId, this.item.id);
  }

  onNextClick() {
    this.itemLoading$.next(true);
    this.navigateToNextItem();
  }

  onPrevClick() {
    this.itemLoading$.next(true);
    this.navigateToPreviousItem();
  }

  onTextSelected(message: IReaderMessage) {
    const field = MESSAGE_TYPE_TO_FIELD_MAP[message.data.type];
    if (!field) {
      return;
    }
    const ctrl = this.form.controls[field.name];
    if (!ctrl) {
      return;
    }

    let value = message.data.text.trim();

    if (field.concat) {
      const arr = ctrl.value.concat([value]);
      ctrl.setValue(arr);
      return;
    }
    if (field.name === 'doi') {
      value = this.extractDoiFromSelection(value);
    }
    ctrl.setValue(value);
  }

  resetForm() {
    this.form.reset();
    this.form.controls['authors'].setValue([]);
  }

  onAutomaticTitleFound(message: IReaderMessage) {
    const ctrl = this.form.controls['title'];
    if (!message.data['title']) {
      return;
    }
    const value = message.data['title'].trim();
    if (!value) {
      ctrl.setValue(value);
    }

    this.searchArticles();
  }

  extractDoiFromSelection(value: string) {
    // remove new line chars that for some reason end up in doi
    value = value.replace(/(\r\n|\n|\r)/gm, "");

    const matchingList = value.match(/10\.\d{3,5}\/[^\s]+/g);

    return (matchingList && matchingList[0]) ? matchingList[0] : value;
  }

  handleError(err: any): Observable<any> {
    this.error = err;
    return throwError(err);
  }
}
