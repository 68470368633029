<div 
  *ngFor="let item of value; let i = index;"
  class="token_item"
  [ngClass]="{'error': getError(i)}">
  <ng-template 
    [ngTemplateOutlet]="tokenTemplateRef" 
    [ngTemplateOutletContext]="{ item: item }">
  </ng-template>
  <span
    class="token_icon" 
    (click)="removeEntry(i)">
  </span>
</div>

<input 
  #input 
  type="text"
  class="token_input"
  [(ngModel)]="text"
  (ngModelChange)="textChange.emit(text)"
  [disabled]="disabled"
  [placeholder]="placeholder"
  (keyup.enter)="onKeyUpEnter($event)"
  (keydown.enter)="onKeyDownEnter($event)"
  (blur)="onBlur($event)">
