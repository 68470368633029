<ng-container *ngIf="(collections && collections.length), else noCollectionsTemplate">
  <div class="form-group">
    <label 
      for="collection"
      class="form-label">
      Select library
    </label>
    <select
      class="form-control" 
      name="collection" 
      id="collection"
      [(ngModel)]="selectedCollection"
      (change)="onSelectionChange()">
      <option value="'initial'" disabled selected>Select your option</option>
      <option 
        *ngFor="let collection of collections"
        [ngValue]="{ id: collection.id, name: collection.name }">
        {{ collection.name }}
      </option>
    </select>
  </div>
  
  <button 
    class="button"
    [disabled]="selectedCollection === 'initial'"
    (click)="onSubmit()">
    Go to matcher
  </button>
</ng-container>

<ng-template #noCollectionsTemplate>
  <ng-container *ngIf="collections && !collections.length">
    Currently there are no unresolved libraries available connected with this account.
  </ng-container>
</ng-template>

<div *ngIf="(authService.isAppForbbidenForUser$ | async)">
  Unfortunatelly, it seems that some resources from this application are not available for your account and/or role.
  <br>
  Please contact your administrator if you want to use this application, or try to log out and then sign in again.
</div>

