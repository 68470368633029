// native
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// service
import { UserService } from '../../services/user.service';

// environment
import { environment } from '../../../../environments/environment';

// meta
import { BUILD_VERSION } from 'build-metadata/build';

@Component({
  selector: 'rcm-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  buildVersion: string;
  userAccountUrl: string;

  constructor(
    public userService: UserService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.buildVersion = BUILD_VERSION;
    this.userAccountUrl = `${environment.baseUrls.readcube}/account`;
  }

  onLogout(): void {
    window.location.href = `${environment.baseUrls.readcube}/session/log_out?redirect=${encodeURIComponent(environment.baseUrls.papers)}`;
  }
}
