import {
  Component,
  Input,
  Output,
  ViewChild,
  ContentChild,
  HostBinding,
  EventEmitter,
  ElementRef,
  TemplateRef,
  ChangeDetectorRef,
  forwardRef,
  HostListener,
  ChangeDetectionStrategy,
  Optional
} from '@angular/core';

import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

import { Observable } from 'rxjs';

export interface ITokenFieldError {
  message: string;
  entries: number[];
}

@Component({
  selector: 'rcp-token-field',
  templateUrl: './token-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TokenFieldComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TokenFieldComponent implements ControlValueAccessor {
  @Input()
  @HostBinding('class.disabled')
  disabled = false;

  @Input()
  text = '';

  @Input()
  focusFirst = true;

  @Input()
  delimiter = ',';

  @Input()
  placeholder = 'Type here...';

  @Input()
  @Optional()
  errors: ITokenFieldError[] = [];

  @Input()
  @Optional()
  search: (text$: Observable<string>) => Observable<any[]>;

  @Output()
  textChange: EventEmitter<string>;

  @ViewChild('input', { static: false })
  inputRef: ElementRef;

  @ContentChild('tokenTemplate', { read: TemplateRef, static: true })
  tokenTemplateRef: TemplateRef<any>;

  value: any[] = [];
  onChange: (arr: string[]) => any;
  onTouched: () => any;

  constructor(
    public changeDetectorRef: ChangeDetectorRef
  ) {
    this.textChange = new EventEmitter<string>();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: string[]) {
    this.value = value;
    this.changeDetectorRef.markForCheck();
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  @HostListener('click')
  focus() {
    this.inputRef.nativeElement.focus();
  }

  getError(index: number): ITokenFieldError {
    return this.errors.find(err => err.entries.includes(index));
  }

  removeEntry(index: number) {
    if (this.disabled) {
      return;
    }
    this.value.splice(index, 1);
    this.onChange(this.value);
    this.onTouched();
  }

  addEntry(item: any) {
    if (this.disabled) {
      return;
    }
    if (this.delimiter) {
      const tmp = item.split(this.delimiter).map(s => s.trim());
      this.value = this.value.concat(tmp);
    } else {
      this.value.push(item);
    }
    this.text = '';
    this.onChange(this.value);
    this.onTouched();
  }

  onKeyUpEnter(event: Event) {
    const text = this.text.trim();
    if (text) {
      this.addEntry(text);
      this.focus();
    }
  }

  onKeyDownEnter(event: Event) {
    const text = this.text.trim();
    if (text) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onBlur(event: Event) {
    const text = this.text.trim();
    if (text) {
      this.addEntry(text);
    }
  }
}
