export const environment = {
    name: 'staging',
    production: false,
    restricted: false,
    baseUrls: {
      papers: '//papersapp.com',
      readcube: '//staging.readcube.com',
      support: '//support.readcube.com',
      app: '//app-dev.readcube.com',
      bucket: '//s3.amazonaws.com/app-dev.readcube.com',
      smartcite: '//s3.amazonaws.com/smartcite.readcube.com',
      smartciteCSL: 'https://smartcite.readcube.com/merged-styles',
      sync: 'https://sync-staging.readcube.com',
      services: 'https://services-staging.readcube.com',
      push: 'https://push-staging.readcube.com/bayeux',
      lambda: 'https://api-gateway.readcube.com',
      metrics: 'https://metrics-api.dimensions.ai',
      playground: 'https://playground.readcube.com'
    },
    clientName: 'matcher',
    uploadWithHash: true,
    maxRequestRetryAttempts: 5,
    bulkRequestSize: 20,
    wsChannelPrefix: '/staging',
    notificationDebounceTime: 5000,
    maxListsDepth: 7,
    chromeExtensionId: 'hokplgjdclnemllndjjaddcoicapnppf',
    firefoxExtensionId: '{003fb763-c222-4734-9773-bc19ba823079}',
    edgeExtensionId: 'EdgeExtension_DigitalScienceAnywhereAccess_bms0cd5w0pryg',
    minExtensionVersion: '2.37.0',
    trackJsApp: 'rcp-webapp-dev',
    trackJsToken: '4587af51bbb542afbbc9d93bf9567159',
    trackJsEnabled: true,
    delayBeforeItemsReload: 2000
  };
  
  import 'zone.js/plugins/zone-error';
  