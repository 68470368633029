// native
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

// service
import { CollectionService } from 'src/app/core/services/collection.service';
import { AuthService } from 'src/app/core/services/auth.service';

// model
import { ICollection } from 'src/app/models/collection.model';

@Component({
  selector: 'rcm-home',
  templateUrl: './home.component.html',
  host: { 'class': 'content-center' }
})
export class HomeComponent implements OnInit, OnDestroy {

  collections: ICollection[];
  collectionSubscription: Subscription;
  selectedCollection: ICollection | string = 'initial';

  emptyCollectionMessage: string;

  constructor(
    private collectionService: CollectionService,
    private router: Router,
    
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.collectionSubscription = this.collectionService.getList().subscribe((collections: ICollection[]) => {
      this.collections = collections.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      })
    });
  }

  onSelectionChange() {
    this.emptyCollectionMessage = null;
  }

  onSubmit() {
    if (this.selectedCollection) {
      this.collectionService.setCurrentCollection(<ICollection>this.selectedCollection);
      this.router.navigateByUrl(`/matcher/${(<ICollection>this.selectedCollection).id}`);
    }
  }

  ngOnDestroy() {
    this.collectionSubscription?.unsubscribe();
  }
}
