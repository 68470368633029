import { AbstractControl } from '@angular/forms';

export function year(control: AbstractControl) {
  const value = control.value;
  const regex = /^\d{4}[.]?$/;

  if (!value) {
    return;
  }

  if (!value.match(regex)) {
    return {
      validYear: true
    };
  }

  return null;
}