// native
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// service
import { ApiService } from './api.service';

// model
import { ICollectionDataResult, ICollection } from 'src/app/models/collection.model';

// environment
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CollectionService {

  currentCollection: ICollection;

  constructor(
    private apiService: ApiService
  ) { }

  getList(): Observable<ICollection[]> {
    return this.apiService.get(`${environment.baseUrls.sync}/matcher/collections`)
      .pipe(
        map((collectionDataResult: ICollectionDataResult) => collectionDataResult.collections)
      );
  }

  setCurrentCollection(collection: ICollection): void {
    this.currentCollection = collection;
  }
}
