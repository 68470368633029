export const MESSAGE_TYPE_TO_FIELD_MAP = {
  'Title': { name: 'title' },
  'Authors': { name: 'authors', concat: true },
  'Journal': { name: 'journal' },
  'Year': { name: 'year' },
  'DOI': { name: 'doi' },
  'PMID': { name: 'pmid' },
  'ISBN': { name: 'isbn' },
  'Abstract': { name: 'abstract' },
  'Patent ID': { name: 'patent_id' }
};

export const JOURNAL_READER_TYPES = 'Title,Authors,Journal,Year,DOI,ISBN,PMID,Abstract';
export const PATENT_READER_TYPES = 'Patent ID';

export const CONFIRM_MATCH_MODAL_TITLE = 'Confirm match';
export const CONFIRM_MATCH_MODAL_TEXT = 'Are you sure you want to auto-complete your reference with selected article?';

export const MARKED_EVENT = 'marked';
export const TITLE_FOUND_EVENT = 'title-found';
export const INITIALIZED_EVENT = 'initialized';

export const PDF_FILE_TYPE = 'pdf';

export const ITEMS_KEY = 'rcmatcher_stored_items';
export const DIRECTION_KEY = 'rcmatcher_stored_direction';
export const PREV_VALUE = 'prev';
export const NEXT_VALUE = 'next';

export const JOURNAL_ARTICLE_TYPE = 'journal_article';
export const PATENT_TYPE = 'patent';
