// native
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// service
import { ApiService } from './api.service';

// model
import { IUser } from 'src/app/models/user.model';

// environment
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user$: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);

  constructor(
    private apiService: ApiService
  ) {
    this.getUser();
  }

  private getUser(): void {
    this.apiService.get(`${environment.baseUrls.services}/users/info_matcher`)
      .subscribe((user: IUser) => {
        this.user$.next(user);
      });
  }
}

