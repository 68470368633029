<div class="header">
    <div 
      class="header_logo"
      (click)="router.navigate([''])">
        <img src="assets/img/logo_small.png" alt="">
    </div>
    <div class="header_info-box">
      <a 
        *ngIf="userService.user$ | async as user" 
        class="header_link" 
        [href]="userAccountUrl" 
        target="_blank">
        {{ user.name }}
      </a>
      <a (click)="onLogout()" class="header_link">
        Logout
      </a>
      <span class="header_link">
        Version: {{ buildVersion }}
      </span>
    </div>
</div>
