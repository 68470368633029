// native
import { NgModule } from "@angular/core";

// component
import { HomeComponent } from './components/home/home.component';

// module
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    HomeComponent
  ]
})
export class HomeModule { }