// native
import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

// service
import { ApiService } from './api.service';
import { LocalStorageService } from './local-storage.service';

// environment
import { environment } from '../../../environments/environment';

// model
import { IItemDataResult, IItem, IItemResolveResponse } from 'src/app/models/item.model';
import { IReaderMessage } from 'src/app/models/selection.model';
import { IExtIds } from 'src/app/models/ext-ids.model';

// validators
import * as CustomValidators from '../../validators';

// constants
import { ITEMS_KEY, DIRECTION_KEY, PREV_VALUE, NEXT_VALUE, JOURNAL_ARTICLE_TYPE, PATENT_TYPE } from 'src/app/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class ResolverService {

  item$: Subject<IItemDataResult> = new Subject<IItemDataResult>();
  selection$: Subject<IReaderMessage> = new Subject<IReaderMessage>();

  collectionNotResolvable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private apiService: ApiService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) { }

  getInitialItem(collectionId: string) {
    const direction = this.localStorageService.getByKey(DIRECTION_KEY);
    const lastItemId = this.getStoredLastItem(collectionId);

    if (lastItemId && direction === PREV_VALUE) {
      this.getPreviousItem(collectionId, lastItemId);
      return;
    }

    this.getNextItem(collectionId, lastItemId);
  }


  getNextItem(collectionId: string, lastItemId?: string): void {
    let params = null;

    if (lastItemId)
      params = new HttpParams().set('last_item_id', lastItemId);

    this.apiService.get(`${environment.baseUrls.sync}/matcher/${collectionId}/next_item`, params)
      .pipe(
        first()
      )
      .subscribe((result: IItemDataResult) => {
        this.collectionNotResolvable$.next(false);

        if (result.total === 0) {
          this.router.navigateByUrl('success');
          return;
        }

        if (result.item) {
          this.item$.next(result);
          lastItemId && this.storeItemPosition(collectionId, lastItemId, NEXT_VALUE);
        }

      }, (error: any) => {
        this.collectionNotResolvable$.next(true);
      });
  }

  getPreviousItem(collectionId: string, lastItemId: string): void {
    let params = new HttpParams().set('last_item_id', lastItemId);

    this.apiService.get(`${environment.baseUrls.sync}/matcher/${collectionId}/prev_item`, params)
      .pipe(
        first()
      )
      .subscribe((result: IItemDataResult) => {
        if (result.item) {
          this.item$.next(result);
          this.storeItemPosition(collectionId, lastItemId, PREV_VALUE);
        }
      });
  }

  getStoredLastItem(collectionId: string): string {
    const storedItems = this.localStorageService.getByKey(ITEMS_KEY);

    return storedItems?.[collectionId];
  }

  storeItemPosition(collectionId: string, lastItemId: string, direction: string): void {
    let storedItems = this.localStorageService.getByKey(ITEMS_KEY);

    if (!storedItems)
      storedItems = {};

    storedItems[collectionId] = lastItemId;

    this.localStorageService.save(ITEMS_KEY, storedItems);
    this.localStorageService.save(DIRECTION_KEY, direction);
  }

  setSelection(readerMessage: IReaderMessage) {
    this.selection$.next(readerMessage);
  }

  updateItem(collectionId: string, itemId: string, item: Partial<IItem>): Observable<IItemResolveResponse> {
    const url = `${environment.baseUrls.sync}/matcher/${collectionId}/update_item/${itemId}`;
    return this.apiService.patch(url, { item });
  }

  resolveItem(collectionId: string, itemId: string, ext_ids: IExtIds): Observable<IItemResolveResponse> {
    const url = `${environment.baseUrls.sync}/matcher/${collectionId}/resolve_item/${itemId}`;
    return this.apiService.patch(url, { ext_ids });
  }

  getItemPatch(form: UntypedFormGroup, formType: string): Partial<IItem> {
    if (formType === JOURNAL_ARTICLE_TYPE) {
      return {
        article: {
          title: form.controls['title'].value || null,
          abstract: form.controls['abstract'].value || null,
          authors: form.controls['authors'].value || null,
          journal: form.controls['journal'].value || null,
          year: form.controls['year'].value || null,
          isbn: form.controls['isbn'].value || null
        },
        ext_ids: {
          pmid: form.controls['pmid'].value || null,
          doi: form.controls['doi'].value || null
        }
      };
    } else if (formType === PATENT_TYPE) {
      return {
        ext_ids: {
          patent_id: form.controls['patent_id'].value || null
        }
      };
    }

  }

  markUnresolvable(collectionId: string, itemId: string): Observable<IItemResolveResponse> {
    return this.apiService.patch(`${environment.baseUrls.sync}/matcher/${collectionId}/mark_unresolvable/${itemId}`);
  }

  initForm(item: IItem, formType: string): UntypedFormGroup {
    if (formType === JOURNAL_ARTICLE_TYPE) {
      return new UntypedFormGroup({
        'title': new UntypedFormControl({
          value: item.article.title,
          disabled: false
        }, []),
        'authors': new UntypedFormControl({
          value: item.article.authors || [],
          disabled: false
        }, []),
        'journal': new UntypedFormControl({
          value: item.article.journal,
          disabled: false
        }, []),
        'year': new UntypedFormControl({
          value: item.article.year && item.article.year.toString(),
          disabled: false
        }, [
          CustomValidators.year
        ]),
        'doi': new UntypedFormControl({
          value: item.ext_ids.doi,
          disabled: false
        }, [
          Validators.pattern(/^10\.\d{3,5}\/[^\s]+$/),
        ]),
        'pmid': new UntypedFormControl({
          value: item.ext_ids.pmid,
          disabled: false
        }, [
          Validators.pattern(/[0-9 $]+/)
        ]),
        'isbn': new UntypedFormControl({
          value: item.article.isbn,
          disabled: false
        }, [
          Validators.pattern(/(ISBN[-]*(1[03])*[ ]*(: ){0,1})*(([0-9Xx][- ]*){13}|([0-9Xx][- ]*){10})/)
        ]),
        'abstract': new UntypedFormControl({
          value: item.article.abstract,
          disabled: false
        })
      });
    } else if (formType === PATENT_TYPE) {
      return new UntypedFormGroup({
        'patent_id': new UntypedFormControl({
          value: item.custom_metadata?.patent_id,
          disabled: false
        }, [])
      });
    }

  }
}
