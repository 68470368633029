<div class="resolver">
  <ng-container *ngIf="readerURL && !isFrameHidden, else loadingTemplate">
    <iframe 
      class="resolver_reader" 
      [ngClass]="{'absolute': (loadingReaderDocument$ | async)}"
      [src]="readerURL" 
      seamless 
      allowtransparency="true">
    </iframe>

    <div *ngIf="loadingReaderDocument$ | async" class="resolver_reader relative">
      <rcm-loader class="center-horizontal-vertical"></rcm-loader>
    </div>
  </ng-container>
  
  <ng-template #loadingTemplate>
    <div class="resolver_reader relative">
      <rcm-loader class="center-horizontal-vertical"></rcm-loader>
    </div>
  </ng-template>

  <div class="resolver_sidebar-container">
    <rcm-resolver-sidebar class="resolver_sidebar-content" (typeChange)="onFormTypeChanged($event)"></rcm-resolver-sidebar>
  </div>
</div>

<div *ngIf="isNonPdfFileTypeNotificationVisible" class="toast">
  Unfortunatelly, this document can't be displayed because it is not a PDF.
</div>

<div *ngIf="resolverService.collectionNotResolvable$ | async" class="toast">
  Unfortunatelly, this library needs to be enabled for resolving.
</div>