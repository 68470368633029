// native
import { NgModule } from "@angular/core";

// component
import { ResolverComponent } from './components/resolver/resolver.component';
import { ResolverSidebarComponent } from './components/resolver-sidebar/resolver-sidebar.component';
import { SuccessComponent } from './components/success/success.component';

// module
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ResolverComponent,
    ResolverSidebarComponent,
    SuccessComponent
  ]
})
export class ResolverModule { }