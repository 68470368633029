import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'rcm-success',
  templateUrl: './success.component.html',
  host: { 'class': 'content-center' }
})
export class SuccessComponent implements OnInit {

  constructor(
    public userService: UserService
  ) { }

  ngOnInit(): void {
  }
}
